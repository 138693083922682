import { useEffect } from "react";
import { Footer } from "./footer";
import { Navbar, NavbarProps } from "./navbar";
import styles from "./styles.module.css";
import config from "config.json";
import { useHistory, useLocation } from "react-router-dom";
import { Routes } from "router";

export interface HomeLayoutProps extends NavbarProps {
  children: any;
}

const HomeLayout = ({ children, active }) => {
  const { push } = useHistory();

  useEffect(() => {
    if (config.whitelabel) push(Routes.login);
  }, [config]);

  return (
    <>
      <Navbar active={active} />
      <main className={styles.main}>{children}</main>
      <Footer />
    </>
  );
};

export { HomeLayout };
