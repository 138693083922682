import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RouteBuilder, Routes } from ".";
import config from "config.json";
import TutorialWidget from "components/tutorialWidget";
import { Banner } from "components/banner";

/**
 * MAIN ROUTER COMPONENT
 *
 * ===============================================
 *
 * This component houses all routes and their respective layouts.
 * To add a route navigate to the route builder and add to the existing list.
 *
 *
 */

const MainRouter: React.FC = () => {
  return (
    <>
      <Switch>
        {RouteBuilder?.length > 0 &&
          RouteBuilder.map((item, idx) => {
            const { Element, path, Layout, props } = item;
            // Checks if a layout exists or not
            const PageComponent = Layout ? (
              <Layout {...props}>
                <Element />
              </Layout>
            ) : (
              <Element />
            );

            return path === Routes.home && config.whitelabel ? (
              <Route exact path={Routes.home}>
                <Redirect to={{ pathname: Routes.login }} />
              </Route>
            ) : (
              <Route
                exact
                key={idx}
                path={path}
                component={() => PageComponent}
              />
            );
          })}
      </Switch>

      {!window.location.pathname.startsWith("/student/register") ? (
        <>
          {!config.whitelabel ? <TutorialWidget /> : ""}
          {/* {!config.whitelabel ? <CountDown /> : ""} */}
          {/* {!config.whitelabel ? <Banner /> : ""} */}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export { MainRouter };
