import { AuthLayout, HomeLayout } from "layout";
import { lazy } from "react";
import { RouteProps } from "react-router-dom";
import { Routes } from "./routes";
import config from "config.json";
const RegisterAsProspect = lazy(() => import("routes/RegisterAsProspect"));
const GetCaptcha = lazy(() => import("routes/getCaptacha"));
const ApplicationForm = lazy(() => import("routes/AgentApplicationForm/form"));
const Applications = lazy(() => import("routes/AgentApplications"));
const Commissions = lazy(() => import("routes/AgentCommissions"));
const Dashboard = lazy(() => import("routes/AgentDashboard"));
const AgentSettings = lazy(() => import("routes/AgentSettings/settings"));
const Courses = lazy(() => import("routes/Courses"));
const CreateProspect = lazy(() => import("routes/CreateProspect"));
const EditProspect = lazy(() => import("routes/EditProspect"));
const Prospects = lazy(() => import("routes/Prospects"));
const TermsAndConditionsPage = lazy(() => import("routes/TermsAndConditions"));
const ViewProspect = lazy(() => import("routes/ViewProspect"));
const Partnerships = lazy(() => import("routes/AgentPartnerships"));
const ForStudents = lazy(() => import("pages/home/students"));
const Blog = lazy(() => import("pages/home/blog"));
const CoursesHome = lazy(() => import("pages/home/courses"));
const ForAgents = lazy(() => import("pages/home/agents"));
const Institutions = lazy(() => import("pages/home/institutions"));
const Pricing = lazy(() => import("pages/home/pricing"));
const EmailVerification = lazy(
  () => import("pages/registration/emailVerification")
);
const Signup = lazy(() => import("pages/registration/signup"));
const ForgotPassword = lazy(
  () => import("pages/authentication/forgotPassword")
);
const Login = lazy(() => import("pages/authentication/login"));
const ResetPassword = lazy(() => import("pages/authentication/resetPassword"));


// Route Builder Item Props
export interface RouteBuilderItem extends RouteProps {
  Layout?: React.FC<any>; // If you wish to add a layout to the page
  Element: React.FC;
  props?: any;
}

/**
 * ROUTE BUILDER
 *
 * ===============================================
 *
 * This is a list of all our application routes.
 *
 * A single item on this list contains the necessary Route props needed by React Router to do it's job.
 *
 * If you wish to add a new route to the application,
 * just fulfill all the necessary props needed by the RouteBuilder item. Ciao!
 *
 */
export const RouteBuilder: RouteBuilderItem[] = [
  {
    path: Routes.home,
    Element: config.whitelabel ? Signup : ForStudents,
    props: {
      active: "students",
    },
    Layout: config.whitelabel ? AuthLayout : HomeLayout,
  },
  {
    path: Routes.agents,
    Element: ForAgents,
    props: {
      active: "agents",
    },
    Layout: HomeLayout,
  },
  {
    path: Routes.pricing,
    Element: Pricing,
    props: {
      active: "pricing",
    },
    Layout: HomeLayout,
  },
  {
    path: Routes.institutions,
    Element: Institutions,
    props: {
      active: "institutions",
    },
    Layout: HomeLayout,
  },
  {
    path: Routes.courses,
    Element: CoursesHome,
    props: {
      active: "courses",
    },
    Layout: HomeLayout,
  },
  {
    path: Routes.blog,
    Element: Blog,
    props: {
      active: "blog",
    },
    Layout: HomeLayout,
  },
  {
    path: Routes.signup,
    Element: Signup,
    Layout: AuthLayout,
  },
  {
    path: Routes.verify,
    Element: EmailVerification,
  },
  {
    path: Routes.login,
    Element: Login,
    Layout: AuthLayout,
  },
  {
    path: Routes.forgotPassword,
    Element: ForgotPassword,
  },
  {
    path: Routes.resetPassword,
    Element: ResetPassword,
  },
  {
    path: Routes.registerAsProspect,
    Element: RegisterAsProspect,
  },
  {
    path: Routes.captcha,
    Element: GetCaptcha,
  },

  // Agent Dashboard
  {
    path: Routes.agentOverview,
    Element: Dashboard,
  },
  {
    path: Routes.agentSettings,
    Element: AgentSettings,
  },
  {
    path: Routes.agentApplications,
    Element: Applications,
  },
  {
    path: Routes.agentApplicationForm,
    Element: ApplicationForm,
  },
  {
    path: Routes.agentCommissions,
    Element: Commissions,
  },
  {
    path: Routes.agentPartnerships,
    Element: Partnerships,
  },
  {
    path: Routes.agentCourses,
    Element: Courses,
  },
  {
    path: Routes.agentProspects,
    Element: Prospects,
  },
  {
    path: Routes.agentCreateProspect,
    Element: CreateProspect,
  },
  {
    path: Routes.agentProspect,
    Element: ViewProspect,
  },
  {
    path: Routes.agentEditProspect,
    Element: EditProspect,
  },
  {
    path: Routes.agentTermsAndConditions,
    Element: TermsAndConditionsPage,
  },

  // Students
  // {
  //   path: Routes.prospectsOverview,
  //   Element: ProspectDashboard,
  // },
  // {
  //   path: Routes.prospectsLogin,
  //   Element: ProspectLogin,
  // },
  // {
  //   path: Routes.studentApplicationForm,
  //   Element: StudentApplicationPage,
  // },
];
